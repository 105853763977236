export const menuItems = [

    {
        id: 2,
        label: "Dashboard",
        icon: "ri-dashboard-line",
        link: '/'
    },
    {
        id: 3,
        label: "Orders",
        icon: "ri-sort-desc",
        isMenuCollapsed: false,
        subItems: [
            {
                id: 4,
                label: 'Draft',
                link: '/orders?status=draft'
            },
            {
                id: 5,
                label: 'PostPay',
                link: '/orders?status=postpay'
            },
            {
                id: 6,
                label: 'Active',
                link: '/orders?status=active'
            },
            {
                id: 7,
                label: 'Progress',
                link: '/orders?status=progress'
            },
            {
                id: 8,
                label: 'Completed',
                link: '/orders?status=completed'
            },
        ]
    },
    {
        id: 28,
        label: "Products",
        icon: "ri-shopping-bag-3-line",
        isMenuCollapsed: false,
        subItems: [
            {
                id: 29,
                label: 'Categories',
                link: '/categories'
            },
            {
                id: 30,
                label: 'Products',
                link: '/products'
            },
        ]
    },
    {
        id: 21,
        label: "Reviews",
        icon: "ri-award-line",
        link: '/reviews'
    },
    {
        id: 14,
        label: "Users",
        icon: "ri-shield-user-line",
        isMenuCollapsed: false,
        subItems: [
            {
                id: 15,
                label: 'Create new',
                link: '/users/create'
            },
            {
                id: 16,
                label: 'Admin',
                link: '/users?role=admin'
            },
            {
                id: 17,
                label: 'Workers',
                link: '/users?role=worker'
            },
            {
                id: 18,
                label: 'Buyers',
                link: '/users?role=buyer'
            },
            {
                id: 19,
                label: 'Staff',
                link: '/users?role=staff'
            }
        ]
    },
    {
        id: 24,
        label: "Payments",
        icon: "ri-money-dollar-box-line",
        link: '/payments'
    },
    {
        id: 27,
        label: "Requirements",
        icon: "ri-questionnaire-line",
        isMenuCollapsed: false,
        subItems: [
            {
                id: 38,
                label: 'Requirements',
                link: '/requirements'
            },
            {
                id: 37,
                label: 'Requirement Sections',
                link: '/requirement-sections'
            },
        ]
    },
    {
        id: 25,
        label: "Settings",
        icon: "ri-settings-2-line",
        isMenuCollapsed: false,
        subItems: [
            
            {
                id: 32,
                label: 'Attributes',
                link: '/attributes'
            },
            {
                id: 26,
                label: 'Addons',
                link: '/addons'
            },
            {
                id: 40,
                label: 'Questions',
                link: '/frequent-questions'
            },
            {
                id: 41,
                label: 'Coupons',
                link: '/coupons'
            },
        ]
    },
    {
        id: 36,
        label: "Payout",
        icon: "ri-refund-2-line",
        link: '/transactions'
    },
];
