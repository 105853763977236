<template>
   <b-nav-item-dropdown right class="notification-list" menu-class="dropdown-lg">
      <template slot="button-content" >
        <span class="nav-link  dropdown-toggle">
          <i class="fe-bell noti-icon"></i>
          <span v-if="unreadNotifications.length" class="badge badge-danger rounded-circle noti-icon-badge">{{unreadNotifications.length}}</span>
        </span>
      </template>
      <a href="javascript:void(0);" class="dropdown-item noti-title">
          <h5 class="m-0">
            <span class="float-right">
            </span>
            Notifications
          </h5>
      </a>

      <notification-list
       v-if="activeNotifs && activeNotifs.length"
       :list="activeNotifs"
       />

      <a
        href="javascript:void(0);"
        class="dropdown-item text-center text-primary notify-item notify-all"
        @click.prevent="$router.push({path: '/notifications'})">
          View all
          <i class="fi-arrow-right"></i>
      </a>
  </b-nav-item-dropdown>
</template>

<script>
import NotificationList from "./NotificationList.vue"
export default {
  components:{
    NotificationList
  },
  data() {
    return {
    }
  },
  computed: {
    pageData() {
      return this.$store.state.notification.pageData
    },
    unreadNotifications() {
      return this.pageData.data.filter((notif) => (notif.is_seen == 0))
    },
    readNotifications() {
      return this.pageData.data.filter((notif) => notif.is_seen == 1 );
    },
    activeNotifs() {
      return this.unreadNotifications.length > 1
        ? [...this.unreadNotifications.slice(0,10), ...this.readNotifications.slice(0,5)]
        : this.pageData.data.slice(0, 10);
    },
    activeUser() {
      return this.$store.state.AppActiveUser
    }
  },
  created(){
    this.$store.dispatch("notification/fetchNotifications")
  },
}

</script>

<style>
.notification-list .dropdown-toggle{
    position: relative;
}
</style>
