

<template>
  <simplebar style="max-height: 230px">
    <div v-for="(notif, index) in list" :key="index">
      <a href="javascript:void(0)"  @click.prevent="navigateTo(notif)" class="dropdown-item notify-item" :class="{'active': !notif.is_seen }">
        <div class="notify-icon bg-soft-primary text-primary">
            <i class="mdi mdi-message-text"></i>
        </div>
        <p class="notify-details">
            <span>{{ notif.title }}</span>
            <small class="text-muted d-block font-10">{{ notif.content | truncate(50)  }}</small>
            <small class="text-muted d-block font-10">{{ notif.created_at | date_ago }}</small>
        </p>
      </a>
    </div>
  </simplebar>
</template>

<script>
export default {
    name: "notification-list",
    props: {
      list: {
        type: Array,
        default:() => ([]),
      },
    },
    methods: {
      navigateTo(notif){
        if(!notif.is_seen){
          this.$store.dispatch("notification/markNotifAsRead", notif.id);
        }
        this.$router.push({path: notif.url });
      },
    },
}
</script>
